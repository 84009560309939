<template>
  <div class="mt-page-wrap">
    <div class="mt-header">
      <div class="mt-container">
        <router-link to="/hautarztpraxis-kuehne">
          <img src="@/assets/images/hautarztpraxis/logo.png" alt="">
        </router-link>
      </div>
    </div>
    <div class="mt-page-content">
      <div class="mt-container">
        <div class="mt-content-wrap">
          <div class="mt-content">
            <div class="mt-card-items">
              <div class="mt-card-item">
                <div class="mt-flex-row-between">
                  <div class="mt-card-profile  mt-card-profile-img">

                    <div class="mt-card-img">
                      <img
                        src="https://cdn.medicosearch.ch/c/684/4236243887e12f780187e5c032930486/5aca1801c2bfaf1d3a131cb3eb9680ba8fd47625.png"
                        alt="Hautarztpraxis Dr. med. A. Kühne">
                    </div>

                    <div class="mt-card-profile-info">
                      <div class="mt-card-info-title"></div>
                      <a
                        href="https://www.medicosearch.ch/hautarztpraxis-dr-med-a-kuhne-grunaustrasse-25-9470-buchs/surgery/96944"
                        class="mt-card-info-name">Hautarztpraxis Dr. med. A. Kühne</a>

                      <div class="mt-static-rating-wrap">
                        <div class="mt-static-rating">
                          <span class="mt-icon-static-rating"></span>
                          <span class="mt-icon-static-rating"></span>
                          <span class="mt-icon-static-rating"></span>
                          <span class="mt-icon-static-rating"></span>
                          <span class="mt-icon-static-rating"></span>
                          <div class="mt-static-rating-inner" style="width: 0%">
                            <span class="mt-icon-static-rating"></span>
                            <span class="mt-icon-static-rating"></span>
                            <span class="mt-icon-static-rating"></span>
                            <span class="mt-icon-static-rating"></span>
                            <span class="mt-icon-static-rating"></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="mt-card-settings">
                  </div>
                </div>

                <div class="mt-flex-row">
                  <div class="mt-flex-column mt-flex-align-start">
                    <div class="mt-card-spec-wrap"></div>
                  </div>
                  <div class="mt-card-appointment-position">
                    <div class="mt-flex-row">
                      <div class="mt-card-space-block">
                        <span class="mt-appointment-title">Ort</span>
                      </div>
                    </div>
                    <div class="mt-card-appointment-line">
                      <div class="mt-flex-row">
                        <div class="mt-card-space-block">
                          <div class="mt-card-space"></div>
                          <div class="mt-card-address">Grünaustrasse 25, 9470 Buchs</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="booking-widget-wrap" class="mt-content">
            <div id="medicosearchWidget"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Cookies from "@/util/Cookies";

export default {
  name: "HautarztpraxisZuweisen",
  mounted() {
    const bookingWidgetWrap = this.$el.querySelector('#booking-widget-wrap')

    const bookingWidgetInitScript = document.createElement('script')
    bookingWidgetInitScript.type = 'text/javascript'
    bookingWidgetInitScript.src = 'https://www.medicosearch.ch/widget/api/js?autoInit=false'
    bookingWidgetInitScript.onload = () => {
      new MedicosearchBookingWidget2({
        token: '2af6ebc9-28d5-4c73-b1d8-37625e2e33ff',
        configId: '608cda87-072e-4423-8aac-cd386f2c223f',
        app: '66f1f9c9-c90a-4031-8dfe-2302b977c17d',
        wrapper: '#medicosearchWidget',
        lang: 'de',
        jwtToken: Cookies.getAccessToken()
      })
    }
    bookingWidgetWrap.appendChild(bookingWidgetInitScript)
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/style.scss";
</style>
