<template>
  <div class="mt-page-wrap">
    <div class="mt-header">
      <div class="mt-container">
        <router-link to="/lindenhof">
          <img src="@/assets/images/lindenhof/logo.svg" alt="">
        </router-link>
      </div>
    </div>
    <div class="mt-page-content">
      <div class="mt-container">
        <div class="mt-content-wrap">
          <div class="mt-content">
            <div class="mt-card-items">
              <div class="mt-card-item">
                <div class="mt-flex-row-between">
                  <div class="mt-card-profile  mt-card-profile-img">

                    <div class="mt-card-img">
                      <img
                        src="https://cdn.medicosearch.ch/c/0c5/42362438865076c001865583540f05c0/dd3b304592d059f0c20affbb7e533112af75c65b.png"
                        alt="Rehaklinik Hasliberg">
                    </div>

                    <div class="mt-card-profile-info">
                    <div class="mt-card-info-title"></div>
                      <a
                        href="https://www.medicosearch.ch/rehaklinik-hasliberg-laueli-89b-6083-hasliberg-hohfluh/surgery/63654628"
                        class="mt-card-info-name">Rehaklinik Hasliberg</a>

                      <div class="mt-static-rating-wrap">
                        <div class="mt-static-rating">
                          <span class="mt-icon-static-rating"></span>
                          <span class="mt-icon-static-rating"></span>
                          <span class="mt-icon-static-rating"></span>
                          <span class="mt-icon-static-rating"></span>
                          <span class="mt-icon-static-rating"></span>
                          <div class="mt-static-rating-inner" style="width: 0%">
                            <span class="mt-icon-static-rating"></span>
                            <span class="mt-icon-static-rating"></span>
                            <span class="mt-icon-static-rating"></span>
                            <span class="mt-icon-static-rating"></span>
                            <span class="mt-icon-static-rating"></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="mt-card-settings">
                    <div class="mt-card-languages">
                      <span>de</span>
                    </div>
                  </div>
                </div>

                <div class="mt-flex-row">
                  <div class="mt-flex-column mt-flex-align-start">
                    <div class="mt-status-text mt-text-video">
                      <span>MedicoVideo</span>
                    </div>
                    <div class="mt-card-spec-wrap"></div>
                  </div>
                  <div class="mt-card-appointment-position">
                    <div class="mt-flex-row">
                      <div class="mt-card-space-block">
                        <span class="mt-appointment-title">Ort</span>
                      </div>
                    </div>
                    <div class="mt-card-appointment-line">
                      <div class="mt-flex-row">
                        <div class="mt-card-space-block">
                          <div class="mt-card-space"></div>
                          <div class="mt-card-address">Laueli 89b, 6083 Hasliberg Hohfluh</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="booking-widget-wrap" class="mt-content">
            <div id="medicosearchWidget"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Cookies from "@/util/Cookies";

export default {
  name: "LindenhofZuweisen",
  mounted() {
    const bookingWidgetWrap = this.$el.querySelector('#booking-widget-wrap')

    const bookingWidgetInitScript = document.createElement('script')
    bookingWidgetInitScript.type = 'text/javascript'
    bookingWidgetInitScript.src = 'https://www.medicosearch.ch/widget/api/js?autoInit=false'
    bookingWidgetInitScript.onload = () => {
      new MedicosearchBookingWidget2({
        token: '53d9b127-1789-41cb-bd0e-3bceae43bd3e',
        configId: 'e9fb2e9c-00ef-467b-8beb-07914468c786',
        app: '32a040b3-f0da-4d06-a30e-09718ac21fbf',
        wrapper: '#medicosearchWidget',
        lang: 'de',
        jwtToken: Cookies.getAccessToken()
      })
    }
    bookingWidgetWrap.appendChild(bookingWidgetInitScript)
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/style.scss";
</style>
