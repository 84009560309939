import axios from 'axios'
import store from '../vuex'
import Cookies from '@/util/Cookies'

class AjaxLoader {
  constructor() {
    this.axios = axios
    this.axios.defaults.withCredentials = true
  }

  async sendGet(url, params = {}, headers = {}, options = {}) {
    let builtHeaders = await this.buildHeaders(headers)
    return this.handleResponse(
      this.axios.get(url, {params: params, headers: builtHeaders, ...options})
    )
  }

  async get(url, config) {
    return this.axios.get(url, config)
  }

  async sendPost(url, params, headers = {}, options = {}) {
    let builtHeaders = await this.buildHeaders(headers)
    return this.handleResponse(
      this.axios.post(url, params, {headers: builtHeaders, ...options})
    )
  }

  async buildHeaders(customHeaders) {
    let headers = await Ajax.getDefaultHeaders()
    return {...headers, ...customHeaders}
  }

  handleResponse(resp) {
    return resp
      .then((response) => {
        return this.preHandlingSuccessfulResponse(response)
      })
      .catch((error) => {
        return this.preHandlingFailedResponse(error)
      })
  }

  preHandlingSuccessfulResponse(response) {
    return response
  }

  preHandlingFailedResponse(error) {
    throw error
  }

}

let ajax = null

export const Ajax = {
  getInstance() {
    if (ajax == null) {
      ajax = new AjaxLoader()
    }
    return ajax
  },
  async getDefaultHeaders() {
    let locale = store.state.locale
    let headers = {...this.buildLocaleHeader(locale)}

    let accessToken = Cookies.getAccessToken()
    if (Boolean(accessToken)) {
      headers = {...headers, ...this.buildAuthHeader(accessToken)}
    }
    return headers
  },
  buildAuthHeader(accessToken) {
    return {'Authorization': 'Bearer ' + accessToken}
  },
  buildLocaleHeader(locale) {
    return {'X-Api-Locale': locale}
  }
}
