<template>
  <div class="mcf-container">
    <div class="mcf-error-page">
      <div class="mcf-error-page-404">
        <div class="mcf-error-pos">
          <div class="mcf-error-page-title">Seite nicht gefunden!</div>
          <div class="mcf-error-page-info">Die Seite, nach der Sie suchen, können wir leider nicht finden.</div>
        </div>
        <div class="mcf-error-img">
          <img src="@/assets/images/error-pages/error404@2x.png" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
	export default {
		name: 'NotFoundPage'
	}
</script>

<style scoped lang="scss">
  @import "@/assets/scss/style.scss";

  .mcf-error-page {
    padding: 60px 0 160px;
    width: 100%;
    text-align: center;
  }

  .mcf-error-page-404 {
    position: relative;
    min-height: 388px;

    .mcf-error-pos {
      position: relative;
      padding: 0 20px 0 0;
      z-index: 1;
    }

    .mcf-error-img {
      width: 46%;
      padding: 20px;
      margin: auto;

      img {
        height: auto;
        width: 100%;
        max-height: 100%;
        max-width: 100%;
      }
    }
  }

  .mcf-error-page-title {
    position: relative;
    font-family: $font;
    font-weight: 700;
    font-size: 60px;
    line-height: 1.2;
    color: #000000;
    padding: 0 0 17px;
    margin: 0 0 20px;

    &:before {
      bottom: 0;
      left: 0;
      height: 1px;
      width: 80px;
      background: rgba($brand-color, 0.3);
    }
  }

  .mcf-error-page-info {
    font-family: $font;
    font-weight: 300;
    font-size: 20px;
    line-height: 1.5;
    width: 50%;
    margin: auto;
  }
</style>
