import store from '../vuex'

export default class UrlManager {
  static composeLocaleUrl (domain, uri, params) {
    let queryParams = ''
    if (params) {
      queryParams = '?' + Object.keys(params).map((item) => `${item}=${params[item]}`).join('&')
    }

    const lang = store.state.locale
    if (lang === process.env.VUE_APP_DEFAULT_LOCALE) {
      return `${domain}${uri}${queryParams}`
    }

    if (domain && !domain.endsWith('/')) {
      domain = domain + '/'
    }
    if (uri && !uri.startsWith('/')) {
      uri = '/' + uri
    }

    return `${domain}${lang}${uri}${queryParams}`
  }
}
