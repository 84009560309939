import { createI18n } from "vue-i18n";

export const ALL_LOCALES = ['de', 'en', 'fr', 'it']

const i18n = createI18n({
  locale: process.env.VUE_APP_DEFAULT_LOCALE,
  messages: {},
  fallbackLocale: process.env.VUE_APP_FALLBACK_LOCALE,
});

export default i18n;
