import { createRouter, createWebHistory } from 'vue-router'
import NotFoundPage from "@/views/error/NotFoundPage.vue";
import LindenhofHome from "@/components/lindenhof/LindenhofHome.vue";
import LindenhofZuweisen from "@/components/lindenhof/LindenhofZuweisen.vue";
import HautarztpraxisHome from "@/components/hautarztpraxis/HautarztpraxisHome.vue";
import HautarztpraxisZuweisen from "@/components/hautarztpraxis/HautarztpraxisZuweisen.vue";

export default createRouter({
  history: createWebHistory(),
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/lindenhof',
      component: LindenhofHome
    },
    {
      path: '/lindenhof/zuweisen',
      component: LindenhofZuweisen
    },
    {
      path: '/hautarztpraxis-kuehne',
      component: HautarztpraxisHome
    },
    {
      path: '/hautarztpraxis-kuehne/zuweisen',
      component: HautarztpraxisZuweisen
    },
    {
      path: '/:pathMatch(.*)*',
      component: NotFoundPage
    }
  ]
})
