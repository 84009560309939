<template>
  <router-view/>
</template>

<script>
  import {AuthService} from "@/util/authService";

  export default {
    async created() {
      let authService = AuthService.getInstance()
      authService.setAuthParamsFromUrl()
        .then(() => authService.getAuthorization())
    },
  }
</script>

<style lang="scss">
@import "@/assets/scss/style.scss";
</style>
