import {createStore} from "vuex";
import i18n, {ALL_LOCALES} from "@/i18n";

const store = createStore({
    state: {
      locale: process.env.VUE_APP_DEFAULT_LOCALE,
    },
    mutations: {
      setLocale(state, locale) {
        if (ALL_LOCALES.indexOf(locale) !== -1) {
          state.locale = locale
          i18n.global.locale = locale
        } else {
          throw new Error('Invalid value!')
        }
        console.log('locale', state.locale)
      },
    },
    actions: {
    },
    getters: {
    },
});

export default store;
