import { Ajax } from '@/util/ajaxLoader'
import Cookies from '../util/Cookies'

const IS_AUTHORIZED_PATH = 'api/v1/auth/token/check'
const REFRESH_TOKEN_PATH = 'api/v1/auth/token/refresh'
const LOGOUT_PATH = 'api/v1/auth/logout'

export const ACCESS_COOKIE_NAME = 'access-token'
export const REFRESH_COOKIE_NAME = 'refresh-token'
const ACCESS_PARAM_NAME = 'accessToken'
const REFRESH_PARAM_NAME = 'refreshToken'
const LOGOUT_PARAM_NAME = 'token'

class Authorization {
  constructor () {
    this.ajax = Ajax.getInstance()
  }

  async isAuthorized () {
    const url = `${process.env.VUE_APP_AUTH_HOST}${IS_AUTHORIZED_PATH}`
    let params = {}
    this.addAccessTokenToParams(params)
    return await this.ajax.sendGet(
      url,
      params,
      this.getDefaultHeaders(),
      this.getDefaultOptions()
    )
  }

  async refreshToken () {
    const url = `${process.env.VUE_APP_AUTH_HOST}${REFRESH_TOKEN_PATH}`
    let params = {}
    this.addRefreshTokenToParams(params)
    return await this.ajax.sendPost(
      url,
      params,
      this.getDefaultHeaders(),
      this.getDefaultOptions()
    )
  }

  async logout () {
    const url = `${process.env.VUE_APP_AUTH_HOST}${LOGOUT_PATH}`
    let params = {}
    this.addLogoutToken(params)
    return await this.ajax.sendGet(
      url,
      params,
      this.getDefaultHeaders(),
      this.getDefaultOptions()
    )
  }

  addAccessTokenToParams (params) {
    const accessToken = Cookies.getAccessToken()
    if (accessToken) {
      params[ACCESS_PARAM_NAME] = accessToken
    }
  }

  addRefreshTokenToParams (params) {
    const refreshToken = Cookies.getRefreshToken()
    if (refreshToken) {
      params[REFRESH_PARAM_NAME] = refreshToken
    }
  }

  addLogoutToken (params) {
    const refreshToken = Cookies.getRefreshToken()
    if (refreshToken) {
      params[LOGOUT_PARAM_NAME] = refreshToken
    }
  }

  getDefaultHeaders () {
    return {
      'X-Service-Name': process.env.VUE_APP_AUTH_SERVICE_NAME,
      'X-Api-Key': process.env.VUE_APP_WEB_APP_KEY
    }
  }

  getDefaultOptions () {
    return { withCredentials: true }
  }
}

let auth = null

export const AuthApi = {
  getInstance () {
    if (!auth) {
      auth = new Authorization()
    }
    return auth
  }
}
