import { ACCESS_COOKIE_NAME, REFRESH_COOKIE_NAME } from '@/api/authorization'

export default class Cookies {
  static getCookieValue (name) {
    let cookiesString = document.cookie

    let result = null
    if (cookiesString != null) {
      let cookies = cookiesString.split('; ')
      for(let cookie of cookies) {
        let nameAndValue = cookie.split('=') //[0] - cookie name and [1] - cookie value
        if (nameAndValue[0] === name) {
          result = nameAndValue[1]
          break
        }
      }
    }
    return result
  }

  static setCookie (cookie) {
    document.cookie = cookie + ';path=/'
  }

  static setCookieValue (name, value) {
    this.setCookie(name + '=' + value)
  }

  static removeCookieValue (name) {
    this.setCookieValue(name, '')
  }

  static getAccessToken () {
    return this.getCookieValue(ACCESS_COOKIE_NAME)
  }

  static getRefreshToken () {
    return this.getCookieValue(REFRESH_COOKIE_NAME)
  }

  static setAccessToken (accessToken) {
    this.setCookieValue(ACCESS_COOKIE_NAME, accessToken)
  }

  static setRefreshToken (refreshCookie) {
    this.setCookieValue(REFRESH_COOKIE_NAME, refreshCookie)
  }

  static removeAccessToken () {
    this.removeCookieValue(ACCESS_COOKIE_NAME)
  }

  static removeRefreshToken () {
    this.removeCookieValue(REFRESH_COOKIE_NAME)
  }
}
